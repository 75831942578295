import React from "react";
import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Home from "./Views/Home/Homepage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./Assets/vendor/animate.css/animate.css";
import "./Assets/vendor/boxicons/css/boxicons.css";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS library
const root = ReactDOM.createRoot(document.getElementById("root"));

// Initialize AOS
AOS.init({
  duration: 900, // Animation duration
});

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

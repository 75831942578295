import React, { useState, useEffect, useRef } from "react";
import classes from "./EmploymentDetails.Module.css";
import Input from "../../../../Components/Input/Input.js";
const EmploymentDetails = ({
  formData,
  setFormData,
  nextStep,
  previousStep,
}) => {
  const [formErrors, setFormErrors] = useState({});
  // Ref for the first div
  const firstDivRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      employmentDetails: {
        ...prevData.employmentDetails,
        [name]: value,
      },
    }));
  };

  const validateFields = () => {
    const errors = {};

    if (!formData.employmentDetails.employmentStatus) {
      errors.employmentStatus = "Please select your employment status.";
    }

    if (
      formData.employmentDetails.employmentStatus === "employed" &&
      !formData.employmentDetails.employerName
    ) {
      errors.employerName =
        "Please enter your employer's name with letters and spaces only.";
    }

    if (
      formData.employmentDetails.employmentStatus === "employed" &&
      !formData.employmentDetails.contractType
    ) {
      errors.contractType = "Please select your contract type.";
    }

    if (
      !formData.employmentDetails.monthlyIncome ||
      formData.employmentDetails.monthlyIncome <= 0
    ) {
      errors.monthlyIncome = "Please enter your monthly income greater than 0.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (validateFields()) {
      nextStep();
    }
  };

  useEffect(() => {
    // Scroll to the first div when the component loads
    if (firstDivRef.current) {
      firstDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return (
    <div ref={firstDivRef}>
      <section
        id="step-2"
        className="card-section mt-5 p-4 bg-light shadow rounded"
        style={classes.step2}
      >
        <div className="row">
          <div className="col-9 col-md-10">
            <h2>Employment Information</h2>
          </div>
          <div className="col-3 col-md-2">2 / 4</div>
        </div>
        <form id="employment-details-form" className="pt-5 pt-md-0">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="employment-status">Employment Status</label>
                <select
                  id="employment-status"
                  name="employmentStatus"
                  className={`form-control ${
                    formErrors.employmentStatus ? "is-invalid" : ""
                  }`}
                  value={formData.employmentDetails.employmentStatus}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select your employment status</option>
                  <option value="employed">Employed</option>
                  <option value="self-employed">Self-Employed</option>
                  <option value="unemployed">Unemployed</option>
                </select>
                {formErrors.employmentStatus && (
                  <div className="invalid-feedback">
                    {formErrors.employmentStatus}
                  </div>
                )}
              </div>
            </div>
            {formData.employmentDetails.employmentStatus === "employed" && (
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="employer-name">Employer Name</label>
                  <input
                    type="text"
                    id="employer-name"
                    name="employerName"
                    className={`form-control ${
                      formErrors.employerName ? "is-invalid" : ""
                    }`}
                    value={formData.employmentDetails.employerName}
                    onChange={handleChange}
                    pattern="[A-Za-z\s]+"
                    placeholder="Please Enter Your Employer's Name"
                    title="Please enter only letters and spaces."
                  />
                  {formErrors.employerName && (
                    <div className="invalid-feedback">
                      {formErrors.employerName}
                    </div>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="contract-type">Contract Type</label>
                  <select
                    id="contract-type"
                    name="contractType"
                    className={`form-control ${
                      formErrors.contractType ? "is-invalid" : ""
                    }`}
                    value={formData.employmentDetails.contractType}
                    onChange={handleChange}
                  >
                    <option value="">Select contract type</option>
                    <option value="permanent">Permanent</option>
                    <option value="temporary">Temporary</option>
                    <option value="fulltime">Full-time</option>
                  </select>
                  {formErrors.contractType && (
                    <div className="invalid-feedback">
                      {formErrors.contractType}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="monthly-income">Monthly Income</label>
                <input
                  type="number"
                  id="monthly-income"
                  name="monthlyIncome"
                  className={`form-control ${
                    formErrors.monthlyIncome ? "is-invalid" : ""
                  }`}
                  value={formData.employmentDetails.monthlyIncome}
                  onChange={handleChange}
                  min="1"
                  placeholder="Please Enter Your Monthly Income"
                  required
                />
                {formErrors.monthlyIncome && (
                  <div className="invalid-feedback">
                    {formErrors.monthlyIncome}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-secondary w-100"
                onClick={() => previousStep(1)}
              >
                Previous
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default EmploymentDetails;

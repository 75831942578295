import React, { useState, useEffect, useRef } from "react";
import classes from "./LoanDetails.Module.css";

const LoanDetails = ({ formData, setFormData, nextStep, previousStep }) => {
  const [formErrors, setFormErrors] = useState({});
  const firstDivRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      loanDetails: {
        ...prevData.loanDetails,
        [name]: value,
      },
    }));
  };

  const validateFields = () => {
    const errors = {};

    // Check for a valid loan amount
    if (
      !formData.loanDetails.loanAmount ||
      formData.loanDetails.loanAmount <= 0
    ) {
      errors.loanAmount = "Please enter a valid loan amount greater than 0.";
    }

    // Check for a valid loan purpose
    if (!formData.loanDetails.loanPurpose) {
      errors.loanPurpose = "Please select the purpose of the loan.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = () => {
    if (validateFields()) {
      nextStep();
    }
  };

  useEffect(() => {
    // Scroll to the first div when the component loads
    if (firstDivRef.current) {
      firstDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return (
    <div ref={firstDivRef}>
      <section
        id="step-3"
        className="card-section mt-5 p-4 bg-light shadow rounded"
        style={classes.step3}
      >
        <div className="row">
          <div className="col-9 col-md-10">
            <h2>Loan Details</h2>
          </div>
          <div className="col-3 col-md-2">3 / 4</div>
        </div>
        <form id="loan-details-form" className="pt-5 pt-md-0">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="loan-amount">Loan Amount</label>
                <input
                  type="number"
                  id="loan-amount"
                  name="loanAmount"
                  className={`form-control ${
                    formErrors.loanAmount ? "is-invalid" : ""
                  }`}
                  value={formData.loanDetails.loanAmount}
                  onChange={handleChange}
                  placeholder="Please Enter Your Loan Amount"
                  min="1"
                />
                {formErrors.loanAmount && (
                  <div className="invalid-feedback">
                    {formErrors.loanAmount}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="loan-purpose">Loan Purpose</label>
                <select
                  id="loan-purpose"
                  name="loanPurpose"
                  className={`form-control ${
                    formErrors.loanPurpose ? "is-invalid" : ""
                  }`}
                  value={formData.loanDetails.loanPurpose}
                  onChange={handleChange}
                >
                  <option value="">Select the purpose of the loan</option>
                  <option value="education">Education</option>
                  <option value="home-improvement">Home Improvement</option>
                  <option value="debt-consolidation">Debt Consolidation</option>
                  <option value="other">Other</option>
                </select>
                {formErrors.loanPurpose && (
                  <div className="invalid-feedback">
                    {formErrors.loanPurpose}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-secondary w-100"
                onClick={() => previousStep(2)}
              >
                Previous
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default LoanDetails;

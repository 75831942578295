import React, { useState, useEffect, useRef } from "react";
const Review = ({ formData, previousStep, submitForm, nextStep }) => {
  const firstDivRef = useRef(null);
  const handleNext = () => {
    nextStep();
  };

  useEffect(() => {
    // Scroll to the first div when the component loads
    if (firstDivRef.current) {
      firstDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return (
    <div ref={firstDivRef}>
      <section
        id="step-4"
        className="card-section mt-5 p-4 bg-light shadow rounded"
      >
        <div className="row">
          <div className="col-9 col-md-10">
            <h2>Review Your Information</h2>
          </div>
          <div className="col-3 col-md-2">4 / 4</div>
        </div>
        <form id="review-form" className="pt-5 pt-md-0">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>First Name: </label>
                <span>{formData.personalInfo.firstName}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Last Name: </label>
                <span>{formData.personalInfo.lastName}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>ID Number: </label>
                <span>{formData.personalInfo.idNumber}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Phone Number: </label>
                <span>{formData.personalInfo.phoneNumber}</span>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <label>Email Address: </label>
                <span>{formData.personalInfo.email}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Employment Status: </label>
                <span>{formData.employmentDetails.employmentStatus}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Employer Name: </label>
                <span>{formData.employmentDetails.employerName}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Employer Contact: </label>
                <span>{formData.employmentDetails.employerContact}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Monthly Income: </label>
                <span>{formData.employmentDetails.monthlyIncome}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Loan Amount: </label>
                <span>{formData.loanDetails.loanAmount}</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label>Loan Purpose: </label>
                <span>{formData.loanDetails.loanPurpose}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-secondary w-100"
                onClick={() => previousStep(3)}
              >
                Previous
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                onClick={handleNext}
                className="btn btn-primary w-100"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Review;
